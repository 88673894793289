<template>
    <div class="switch" :class="{'switch__on': value}" @mousedown.stop.prevent="$emit('input')">
        <div class="switch__handle"></div>
    </div>
</template>

<script>
export default {
    name: "HtmlSwitch",
    props: {
        value: Boolean,
    }
};
</script>

<style scoped lang="scss">
.switch {
    width: 20px;
    height: 9px;
    border-radius: 5px;
    cursor: pointer;
    background: rgba(130, 130, 130, 0.2);

    .switch__handle {
        top: -1px;
        left: -1px;
        width: 11px;
        height: 11px;
        display: block;
        position: relative;
        border-radius: 7px;
        background: #6B6B6B;
        transition: all .2s ease;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }

    &.switch__on {
        background: rgba(180, 193, 123, 0.3);

        .switch__handle {
            background: #b4c17b;
        }

        .switch__handle, .switch__multiple .switch__handle {
            transform: translateX(11px);
        }
    }
}
</style>