<template>
    <div class="thumbs-bar">
        <div class="thumbs-bar__scroller custom-scroll">
            <slide_preview
                v-for="(slide, slideIndex) in slides"
                :index="slideIndex"
                :key="slide.id"
                :slide="slide"
                :active="slideIndex == activeSlide"
                :size="size"
                :meta="meta"
                :autoHeight="true"
            />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import SlidePreview from "@/components/viewer/SlidePreview";

export default {
    name: "SlidePreviews",
    props: ['slides', 'activeSlide', 'size', 'meta'],
    components: {
        slide_preview: SlidePreview
    }
}
</script>
