<template>
    <div class="process-message"
         :class="{'process-message_on': message.on}"
         @mousedown.stop.prevent=""
         @mouseup.stop.prevent="">
        <div class="process-message__dialog">
            <div class="process-message__name">{{ message.name }}</div>

            <template v-if="message.type === MsgType.COUNT">
                <div class="process-message__text">
                    <span v-if="!!message.text" v-html="$t(message.text)"/>
                    <span v-if="!!message.count" v-html="message.count"/>
                </div>
            </template>
            <template v-else-if="message.type === MsgType.LIST">
                <div class="process-message__text">
                    <template v-for="(li, idx) in message.text">
                        <span :key="idx">{{  $t(li) }}</span><br>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="process-message__text" v-if="message.text != ''" v-html="$t(message.text)"></div>
            </template>

            <div class="process-message__progressbar"
                 v-if="!message.ok"
                 :class="{'process-message__progressbar_loop': message.loop}">
                <div class="process-message__progressbar-strip process-message__progressbar-strip_bg"/>
                <div
                    class="process-message__progressbar-strip process-message__progressbar-strip_fill"
                    :style="{'transform': 'translateX(' + (message.percentage - 100) + '%)'}"/>
            </div>
            <button
                class="process-message__cancel"
                v-if="message.cancellable"
                :class="{'process-message__cancel_in-process': message.cancel}"
                @mousedown.stop.prevent=""
                @mouseup.stop.prevent="cancelProcessMessage"
            >
                {{ $t('cancel') }}
            </button>
            <button class="process-message__ok"
                    v-if="message.ok"
                    @mousedown.stop.prevent=""
                    @mouseup.stop.prevent="okProcessMessage">OK
            </button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { ProcessMsgType } from '@/enum/Message';

export default {
    name: 'ProcessMessage',
    props: {
        message: Object
    },
    data: () => ({
        MsgType: ProcessMsgType
    }),
    methods: {
        cancelProcessMessage () {
            this.$emit('updateMsg', { key: 'on', val: false });
        },
        okProcessMessage () {
            this.$emit('updateMsg', { key: 'on', val: false });
            setTimeout(() => this.$emit('updateMsg', { key: 'ok', val: false }), 1000);
        }
    }
};
</script>

<style lang="scss">
.process-message {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #1c1e1fee;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease;

    &_on {
        opacity: 1;
        pointer-events: auto;

        .process-message__progressbar {
            &_loop {
                .process-message__progressbar-strip_fill {
                    animation-name: progress-loop;
                    animation-duration: 1.5s;
                    animation-iteration-count: infinite;
                }
            }
        }
    }

    &__dialog {
        width: 500px;
        // height: 240px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        background-color: #2a2c2d;
    }

    &__name {
        height: 45px;
        background-color: #1c1e1f;
        position: relative;
        width: 100%;
        line-height: 45px;
        font-weight: bold;
        font-size: 16px;
        color: #fff;
        padding-left: 26px;
        padding-right: 26px;
        box-sizing: border-box;
    }

    &__text {
        padding-left: 26px;
        padding-right: 26px;
        margin-top: 30px;
        margin-bottom: -15px;
        color: #fffa;
        font-size: 16px;

        a {
            color: #b4c17b;
        }

        .dialog-table {
            // margin-left: auto;
            // margin-right: auto;
            text-align: left;
            margin: 15px auto;

            th {
                font-size: 36px;
                padding-left: 0;
                font-weight: normal;
            }

            td {
                padding-left: 0;
            }
        }
    }

    &__progressbar {
        margin-top: 45px;
        margin-left: 26px;
        margin-bottom: 45px;
        box-sizing: border-box;
        width: calc(100% - 52px);
        height: 4px;
        position: relative;
        overflow: hidden;
        border-radius: 2px;

        &-strip {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 2px;
            transition: transform 1s ease;

            &_bg {
                background-color: #1c1e1f;
            }

            &_fill {
                background-color: #b4c17b;
                transform: translateX(-100%);
            }
        }
    }

    &__cancel, &__ok {
        margin-bottom: 30px;
        align-self: center;
        width: 110px;
        height: 30px;
        border-radius: 3px;
        border: solid 1px #404447;
        background-color: rgba(255, 255, 255, 0.07);
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        transition: background-color .3s ease;

        &:hover {
            background-color: rgba(255, 255, 255, 0.12);
        }
    }

    &__ok {
        margin-top: 50px;
    }

}
</style>

<i18n>
{
    "en": {
        "cancel": "Cancel",
        "generatingSlides": "Generating slides",
        "processingSlide": "Processing slide "
    },
    "ru": {
        "cancel": "Отмена",
        "generatingSlides": "Создание слайдов",
        "processingSlide": "Обработка слайдов "
    }
}
</i18n>
