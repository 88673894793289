<template>
    <div class="slides-viewer" :class="classes" oncontextmenu="return false">
        <process_message :message="processMessage"></process_message>
        <div class="bar top-bar">
            <div class="presentation-name" v-if="slides.length > 0">{{ meta.name }}</div>
            <div class="service-bar" v-if="presUid == undefined">
                <div class="service-bar__item">
                    <div class="service-bar__title-wrapper" @click="shareLinkActive = !shareLinkActive"
                         @mouseup.stop="">
                        <svg width="16px" height="14px" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Viewer" transform="translate(-1388.000000, -17.000000)" fill="#FFFFFF"
                                   fill-rule="nonzero">
                                    <g id="top" transform="translate(-3.000000, -1.000000)">
                                        <g id="Share" transform="translate(1389.000000, 16.000000)">
                                            <g id="ic-share-24px" transform="translate(0.000000, 1.000000)">
                                                <path
                                                    d="M12,10.72 C11.4933333,10.72 11.04,10.92 10.6933333,11.2333333 L5.94,8.46666667 C5.97333333,8.31333333 6,8.16 6,8 C6,7.84 5.97333333,7.68666667 5.94,7.53333333 L10.64,4.79333333 C11,5.12666667 11.4733333,5.33333333 12,5.33333333 C13.1066667,5.33333333 14,4.44 14,3.33333333 C14,2.22666667 13.1066667,1.33333333 12,1.33333333 C10.8933333,1.33333333 10,2.22666667 10,3.33333333 C10,3.49333333 10.0266667,3.64666667 10.06,3.8 L5.36,6.54 C5,6.20666667 4.52666667,6 4,6 C2.89333333,6 2,6.89333333 2,8 C2,9.10666667 2.89333333,10 4,10 C4.52666667,10 5,9.79333333 5.36,9.46 L10.1066667,12.2333333 C10.0733333,12.3733333 10.0533333,12.52 10.0533333,12.6666667 C10.0533333,13.74 10.9266667,14.6133333 12,14.6133333 C13.0733333,14.6133333 13.9466667,13.74 13.9466667,12.6666667 C13.9466667,11.5933333 13.0733333,10.72 12,10.72 Z"
                                                    id="Shape"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div class="service-bar__item-title">
                            {{ $t('share') }}
                        </div>
                    </div>
                    <div
                        class="service-bar__share"
                        v-if="shareLinkActive == true"
                        :class="{'service-bar__share_on': shareLink}"
                        @mouseup.stop=""
                    >
                        <div class="service-bar__share-title">
                            <span>{{ $t('publicLink') }}</span>
                            <HtmlSwitch :value="shareLink" @input="() => toggleShareLink()" />
                        </div>
                        <div class="flex-row" :class="{'flex-row_visible': shareLink}">
                            <div :title="$t('copyToClipboard')">
                                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                                     @click.stop="copyShareLink()"
                                     :class="{'share-link-copied': linkCopyAnimation}">
                                    <path fill="none" d="M0 0h20v20H0z"/>
                                    <g>
                                        <path fill="#fff"
                                              d="M13.678.833H7.5c-.92 0-1.667.749-1.667 1.667v11.667c0 .919.748 1.666 1.667 1.666h8.333c.92 0 1.667-.747 1.667-1.666V4.655L13.678.833zm2.156 13.334H7.5V2.5h5v3.333h3.333l.001 8.334z"/>
                                        <path fill="#fff"
                                              d="M4.167 4.167H2.5V17.5c0 .92.747 1.667 1.667 1.667h10V17.5h-10V4.167z"/>
                                    </g>
                                </svg>
                            </div>
                            <input type="text" v-model="shareLink"/></div>
                    </div>
                </div>
            </div>
        </div>
        <slide-previews
            v-if="slides.length > 0 && !isIframe"
            :slides="slides"
            :activeSlide="activeSlide"
            :size="meta.size"
            :meta="meta"
        />
        <div class="main-block" :class="{'main-block_iframe': isIframe}">
            <div class="workspace">
                <div class="canvas" :class="{'canvas__fullscreen' : isFullscreen}">
                    <animation_controller
                        v-if="vjsons"
                        ref="animation_controller"
                        :vjsons="vjsons"
                        :resizeFlag="resizeFlag"
                        :boxed="meta.boxed"
                        :animationPreview="false"
                        @slideindexchanged="activeSlide = $event"
                        @init="acReady = true"
                    />
                </div>
            </div>
        </div>
        <div class="bar bottom-bar" @mouseup.stop="">
            <div class="handlers">
                <div class="handlers__button handlers handlers__button_prev"
                     :class="{'handlers__button_disabled': atTimelineStart}" @click.stop="prevElem()"></div>
                <div class="handlers__current" v-if="slides.length > 0">
                    <span>{{ currentFrameText }}</span><span> /</span><span>{{ slides.length }}</span></div>
                <div class="handlers__button handlers handlers__button_next"
                     :class="{'handlers__button_disabled': atTimelineEnd}" @click.stop="nextElem()"></div>
            </div>
            <div class="service-bar">
                <div class="service-bar__item" v-if="!isFullscreen" @click.stop.prevent="gotoFullscreen()"
                     @mouseup.stop.prevent="">
                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none">
                            <path d="M0 0h20v20H0z"/>
                            <path fill="#FFF"
                                  d="M8.333 6.667v6.666L12.5 10 8.333 6.667zm7.5-4.167H4.167C3.25 2.5 2.5 3.25 2.5 4.167v11.666c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667zm0 13.333H4.167V4.167h11.666v11.666z"/>
                        </g>
                    </svg>
                    <div class="service-bar__item-title">
                        {{ $t('present') }}
                    </div>
                </div>
                <div class="service-bar__item" v-if="isFullscreen" @click.stop.prevent="exitFullscreen()"
                     @mouseup.stop.prevent="">
                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none">
                            <path d="M0 0h20v20H0z"/>
                            <path fill="#FFF"
                                  d="M8.408 12.992l1.175 1.175L13.75 10 9.583 5.833 8.408 7.008l2.15 2.159H2.5v1.666h8.058l-2.15 2.159zM15.833 2.5H4.167c-.925 0-1.667.75-1.667 1.667V7.5h1.667V4.167h11.666v11.666H4.167V12.5H2.5v3.333c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667z"/>
                        </g>
                    </svg>
                    <div class="service-bar__item-title">{{ $t('exit') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

import Vue from 'vue';
import _ from 'lodash';
import $ from 'jquery';
import JSZip from 'jszip';

import Ajax from '@/mixins/Ajax';
import Fonts from '@/mixins/fonts';
import { apiPost, customApiRequest } from '@/models/Api';
import Fullscreen from '@/mixins/Fullscreen';
import AnimationControllerBar from '@/mixins/AnimationControllerBar';

import SlidePreviews from '@/components/viewer/SlidePreviews';
import ProcessMessage from '@/components/editor/ProcessMessage';
import AnimationController from '@/components/AnimationController';
import HtmlSwitch from '@/components/BaseElements/HtmlSwitch';

const isIframe = window.parent !== window;

export default {
    name: 'Viewer',
    provide() {
        return {
            currentPage: this
        }
    },
    components: {
        HtmlSwitch,
        slidePreviews: SlidePreviews,
        process_message: ProcessMessage,
        animation_controller: AnimationController
    },
    mixins: [
        Fonts,
        Ajax,
        Fullscreen,
        AnimationControllerBar
    ],
    data() {
        return {
            who: 'viewer',
            isPage: true,

            vjsons: null,
            resizeFlag: false,

            slides: [],
            meta: undefined,
            size: {
                width: 0,
                height: 0
            },
            activeSlide: 0,
            shareLink: '',
            shareLinkActive: false,

            showThumbsBar: false,
            showHorizontalBars: false,

            isFullscreen: false,

            userId: undefined,
            presentationId: undefined,
            presUid: undefined,

            linkCopyAnimation: false,

            processMessage: {
                on: true,
                ok: false,
                name: this.$t('loading'),
                text: '',
                percentage: 0,
                loop: true,
                cancellable: false,
                cancel: false
            },

            isIframe
        }
    },
    created() {
        this.init();
    },
    mounted() {
        document.body.classList.add('viewer')

        // Ресайз окна
        window.addEventListener(
            'resize',
            e => {
                this.resize();
            });
        window.addEventListener(
            'mouseup',
            e => {
                this.shareLinkActive = false;
                return false;
            });
        window.addEventListener(
            'keydown',
            e => {
                // console.log e.keyCode
                switch (e.keyCode) {
                    // Space, ArrowLeft
                    case 32:
                    case 39:
                    case 34:
                    case 40:
                        return this.nextElem();
                    // ArrowRight
                    case 37:
                    case 33:
                    case 8:
                    case 38:
                        return this.prevElem();
                    // F5
                    case 116:
                        e.preventDefault();
                        return this.gotoFullscreen();
                    // ,
                    case 188:
                        return this.gotoStart();
                    // .
                    case 190:
                        return this.$refs.animation_controller.gotoLastSlide();
                }
            });
    },
    watch: {
        activeSlide(val) {
            if(this.$refs.animation_controller.slideIndex !== val) {
                this.gotoSlide(val);
            }
        }
    },
    computed: {
        classes() {
            return {
                'fullscreen': this.isFullscreen,
                'show-horizontal-bars': this.showHorizontalBars,
                'show-thumbs-bar': this.showThumbsBar
            };
        }
    },
    methods: {
        init() {
            try {
                const url = new URL(window.location.href);
                this.presentationId = url.searchParams.get('id');
                this.presUid = url.searchParams.get('uid');

                if(this.presentationId !== null) {
                    this.getContent();
                } else if(this.presUid !== null) {
                    this.getContentByUid();
                } else {
                    throw 'Error';
                }
            } catch(e) {
                throw { code: 404, message: 'unknown_page' };
            }

        },
        resize() {
            this.resizeFlag = !this.resizeFlag;
        },
        responseHandler(response) {
            const that = this;
            if(response?.status == null) {
                return;
            }

            if(response.data.all != null) {
                console.log('Hello ResponseHandler', that.storageUrl + '/' + response.data.all);
                return $.ajax({
                    url: that.storageUrl + '/' + response.data.all,
                    success(dat) {
                        console.log('Success: ', dat);
                        return JSZip.loadAsync(
                            dat,
                            { base64: true }
                        ).then(zip => {
                            return zip.file(Object.keys(zip.files)[0]).async('string').then(str => {
                                const json = JSON.parse(str);
                                that.size.width = json.meta.size.w;
                                that.size.height = json.meta.size.h;
                                that.vjsons = json;

                                document.title = response.data.name;

                                that.slides = _.cloneDeep(json.slides);
                                that.meta = _.cloneDeep(json.meta);
                                that.meta.name = response.data.name;
                                Vue.nextTick(
                                    function () {
                                        this.afterInit();
                                    },
                                    that
                                );
                            });
                        });
                    }
                }).done(dat => console.log('Done: ', dat)).fail(dat => console.log('Fail: ', dat));
            }
        },
        async getContent() {
            const content = await apiPost('/api/v1/viewer/content', { uid: this.presentationId }, false);

            if(content) {
                await this.parseContent(content);
                this.getShareLink(content);
            }
        },
        async getContentByUid() {
            const content = await apiPost('/api/v1/share/content', { uid: this.presUid }, false);

            if(content) {
                await this.parseContent(content);
            }
        },
        async parseContent(content) {
            const { all, name } = content;
            document.title = name;

            const res = await customApiRequest('get', `${this.storageUrl}/${all}`);

            if(res) {
                const zip = await JSZip.loadAsync(res, { base64: true });
                const str = await zip.file(Object.keys(zip.files)[0]).async('string');
                const json = JSON.parse(str);

                this.size.width = json.meta.size.w;
                this.size.height = json.meta.size.h;
                this.vjsons = json;
                this.slides = _.cloneDeep(json.slides);
                this.meta = _.cloneDeep(json.meta);
                this.meta.name = name;
                Vue.nextTick(() => this.afterInit());
            }
        },
        toggleShareLink() {
            if(this.shareLink) {
                this.unShare();
            } else {
                this.share();
            }
        },
        async share() {
            const res = await apiPost(`/api/v1/presentation/${this.presentationId}/public`);

            if(res && res.url != null) {
                this.shareLink = res.url;
                this.shareLinkActive = true;
            }
        },
        async getShareLink() {
            const res = await apiPost(`/api/v1/presentation/${this.presentationId}/public/url`);

            if(res && res.url != null) {
                this.shareLink = res.url;
            }
        },
        copyShareLink() {
            const el = document.createElement('textarea');
            el.value = this.shareLink;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.linkCopyAnimation = true;

            setTimeout(() => this.linkCopyAnimation = false, 300);
        },
        async unShare() {
            const res = await apiPost(`/api/v1/presentation/${this.presentationId}/private`);

            if(res) {
                this.shareLink = '';
            }
        },
        gotoFullscreen() {
            this.gotoStart();
            const slidesViewer = this.$el;
            this.fullscreen(slidesViewer);
            this.isFullscreen = true;
        },
        gotoStart() {
            this.$refs.animation_controller.gotoFrame(1);
        },
        gotoSlide(index) {
            this.$refs.animation_controller.gotoSlide(index);
        },
        gotoFirstSlide() {
            this.$refs.animation_controller.gotoSlide(0);
        },
        nextElem() {
            this.$refs.animation_controller.next();
        },
        prevElem() {
            this.$refs.animation_controller.prev();
        },
        fullscreenNext() {
            if(this.isFullscreen) {
                this.nextElem();
                return;
            }
        },
        fullscreenPrev() {
            if(this.isFullscreen) {
                this.prevElem();
                return;
            }
        },
        setActiveSlide(slideNumber) {
            if((slideNumber < 0) ||
                (slideNumber > (this.slides.length - 1)) ||
                (this.activeSlide === slideNumber)) {
                return false;
            }
            this.activeSlide = slideNumber;
            return true;
        },
        afterInit() {
            this.processMessage.on = false;
            this.gotoFirstSlide();
        }
    }
}
</script>

<i18n>
{
    "en": {
        "loading": "Loading...",
        "share": "Share",
        "publicLink": "Public link",
        "copyToClipboard": "Copy to clipboard",
        "present": "Present",
        "exit": "Exit"
    },
    "ru": {
        "loading": "Загрузка...",
        "share": "Поделиться",
        "publicLink": "Общедоступная ссылка",
        "copyToClipboard": "Скопировать в буфер обмена",
        "present": "Презентация",
        "exit": "Выход"
    }
}
</i18n>

<style lang="scss">
// Здесь было оооочень много импортов из разных файлов, я просто вставил готовый css файл, потом можно поправить...

// import '../../common/common.scss';
// import '../editor/components/presentation/presentation.scss';
// import '../editor/components/slide/slide.scss';
// import '../editor/components/text/text.scss';
// import '../editor/components/slide_previews/slide_previews.scss';
// import '../editor/components/slide_preview/slide_preview.scss';
// import './viewer.scss';

body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    color: #3A3B41;
    -webkit-text-size-adjust: 100%;
}

.spinner {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.spinner_active {
    opacity: 1;
    pointer-events: auto;
}

.spinner_bg_white {
    background: rgba(255, 255, 255, 0.97);
}

.spinner svg {
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: spin 1s infinite;
    animation: spin 1s infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(180deg);
        transform: translate(-50%, -50%) rotate(180deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(180deg);
        transform: translate(-50%, -50%) rotate(180deg);
    }
}

@-webkit-keyframes progress-loop {
    from {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@keyframes progress-loop {
    from {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

.page {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    min-height: 100vh;
    background: #F5F6F9;
}

.page.text-page {
    padding: 0 16px;
}

@media (max-width: 600px) {
    .page.text-page {
        padding: 0;
    }
}

.page a {
    color: #82568E;
}

.page h1 {
    font-size: 42px;
    line-height: 48px;
}

.page h2 {
    font-size: 28px;
    line-height: 32px;
}

.page h3 {
    font-size: 21px;
    line-height: 24px;
}

.page__content {
    position: relative;
    display: block;
    max-width: 800px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 96px auto 24px;
    padding: 48px 96px;
    background: #fff;
    -webkit-box-shadow: 0 0 15px rgba(41, 0, 41, 0.07);
    box-shadow: 0 0 15px rgba(41, 0, 41, 0.07);
    border-radius: 6px;
    font-size: 16px;
    line-height: 24px;
}

.page__content_hid {
    display: none;
}

.page__content_show {
    display: block;
}

@media (max-width: 768px) {
    .page__content {
        padding: 48px;
    }
}

@media (max-width: 600px) {
    .page__content {
        padding: 48px 24px;
    }
}

.page__block {
    position: relative;
    display: block;
    padding: 72px 0;
}

.page__block-title {
    position: relative;
    display: block;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    margin: 0 auto;
}

.page__block-text {
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 32px;
    margin: 0 auto;
    padding: 21px 0;
}

.header {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 56px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background: #fff;
    -webkit-box-shadow: 0 0 15px rgba(41, 0, 41, 0.07);
    box-shadow: 0 0 15px rgba(41, 0, 41, 0.07);
    padding-left: 300px;
    padding-right: 300px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@media (max-width: 1920px) {
    .header {
        padding-left: 300px;
        padding-right: 300px;
    }
}

@media (max-width: 1600px) {
    .header {
        padding-left: 210px;
        padding-right: 210px;
    }
}

@media (max-width: 1366px) {
    .header {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media (max-width: 1024px) {
    .header {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media (max-width: 800px) {
    .header {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 480px) {
    .header {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width: 400px) {
    .header {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 320px) {
    .header {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.header__nav {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: 56px;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

@media (max-width: 600px) {
    .header__nav {
        display: none;
    }
}

.header__nav-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    margin: 0 0 0 56px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header__nav-item:first-child {
    margin-left: 0;
}

.header__nav-item a {
    position: relative;
    display: block;
    color: #3A3B41;
    opacity: .5;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    font-size: 11px;
    letter-spacing: 1px;
    padding: 3px 0;
    text-decoration: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-bottom: 2px solid transparent;
    text-transform: uppercase;
    font-weight: bold;
}

.header__nav-item_active {
    border-bottom: 2px solid #82568E;
}

.header__nav-item_active a {
    opacity: 1;
}

.header__nav-item:hover a {
    opacity: 1;
}

.header__right-block {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header__link {
    position: relative;
    display: inline-block;
    font-size: 11px;
    letter-spacing: 1px;
    padding: 3px 0;
    text-decoration: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-bottom: 2px solid #82568E;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 22px;
    padding-bottom: 0;
}

.header__link:not(:last-child) {
    margin-right: 8px;
}

.header .logo {
    position: relative;
    display: block;
    width: 130px;
}

.header .logo img {
    position: relative;
    display: block;
    width: 100%;
}

.header .logo_beta:after {
    position: absolute;
    display: block;
    content: 'β';
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    height: 15px;
    width: 15px;
    color: #fff;
    background-color: #E46F6F;
    border-radius: 50%;
    right: -12px;
    top: -10px;
}

.nav {
    background: #333;
}

.nav__item {
    position: relative;
    display: block;
    color: #fff;
}

form label {
    position: relative;
    display: block;
    color: #6D6C77;
    font-size: 12px;
}

form input[type="text"], form input[type="password"] {
    position: relative;
    display: block;
    height: 24px;
    padding: 5px 0;
    width: 100%;
    background: none;
    border: none;
    border-bottom: 2px solid #ddd;
    outline: none;
    font-size: 16px;
}

form input[type="text"]:focus, form input[type="password"]:focus {
    border-bottom: 2px solid #82568E;
}

form input[type="checkbox"] {
    height: 14px;
    width: 14px;
    margin: 0 8px 0 0;
}

form select {
    position: relative;
    display: block;
    width: 100%;
    padding: 5px 0;
    height: 35px;
    font-size: 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 0;
    outline: none;
    border: 0;
    border-bottom: 1px solid #aaa;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

form select option:disabled {
    opacity: .5;
}

form textarea {
    min-width: 500px;
    min-height: 300px;
}

.context-menu {
    padding: 0;
    color: #3A3B41;
    background: #eee;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    overflow: hidden;
    -webkit-transition: opacity .1s ease, -webkit-transform 0s linear .1s;
    transition: opacity .1s ease, -webkit-transform 0s linear .1s;
    -o-transition: opacity .1s ease, transform 0s linear .1s;
    transition: opacity .1s ease, transform 0s linear .1s;
    transition: opacity .1s ease, transform 0s linear .1s, -webkit-transform 0s linear .1s;
    opacity: 0;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    transform: scale(1, 0);
}

.context-menu__dark {
    background: #3E3F41;
    color: #eee;
}

.context-menu__dark .context-menu__item {
    border-color: rgba(255, 255, 255, 0.1);
}

.context-menu__dark .context-menu__item:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
}

.context-menu_active {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transition: opacity .3s ease, -webkit-transform 0s linear;
    transition: opacity .3s ease, -webkit-transform 0s linear;
    -o-transition: opacity .3s ease, transform 0s linear;
    transition: opacity .3s ease, transform 0s linear;
    transition: opacity .3s ease, transform 0s linear, -webkit-transform 0s linear;
}

.context-menu__item {
    position: relative;
    display: block;
    font-size: 14px;
    line-height: 14px;
    padding: 10px 20px;
    cursor: pointer;
}

.context-menu__item:last-child {
    border: none;
}

.context-menu__item:hover {
    background: #ddd;
}

.tabs {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-height: 100%;
}

.tabs__padding {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.tabs__head {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    background: #E9EAEE;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.tabs__head-item {
    position: relative;
    display: block;
    padding: 12px 0 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: 40px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    opacity: 0.5;
    font-size: 14px;
}

.tabs__head-item:hover {
    opacity: 1;
    border-color: rgba(0, 0, 0, 0.1);
}

.tabs__head-item.active {
    opacity: 1;
    border-color: #82568E;
}

.tabs__body {
    position: relative;
    display: block;
    overflow: auto;
}

.tabs__body .tabs__padding {
    display: block;
}

.tabs__body-item {
    position: relative;
    display: block;
}

.btn {
    -webkit-appearance: none;
    position: relative;
    display: inline-block;
    background: #82568E;
    color: #fff;
    padding: 12px 36px;
    border-radius: 12px;
    border: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    white-space: nowrap;
}

.btn:hover {
    opacity: 0.9;
}

.btn__no-bg {
    height: auto;
    color: #82568E;
    background: #f5f5f5;
}

.btn__no-bg:hover {
    background: #f0f0f0;
}

.btn__no-bg-dark {
    background: #0001;
}

.btn__no-bg-dark:hover {
    background: #0002;
}

.icon-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.icon-btn__icon {
    position: relative;
    display: block;
}

.icon-btn__icon svg {
    position: relative;
    display: block;
}

.icon-btn__title {
    position: relative;
    display: block;
    font-size: 14px;
    margin-left: 10px;
}

.trash-btn {
    position: relative;
    display: inline-block;
    vertical-align: top;
    opacity: .3;
    cursor: pointer;
    height: 26px;
    width: 28px;
}

.trash-btn:hover {
    opacity: 1;
}

.trash-btn svg {
    position: relative;
    display: block;
    margin: 0 auto;
    margin-top: 2px;
    width: 20px;
    height: 20px;
}

.close-btn {
    height: 20px;
    width: 20px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    cursor: pointer;
}

.close-btn:hover {
    opacity: .8;
}

.close-btn:before, .close-btn:after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    margin-top: -1px;
    width: 100%;
    height: 2px;
    background: #82568E;
}

.close-btn:after {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.select {
    position: relative;
    display: block;
    height: 24px;
    overflow: visible;
    font-size: 14px;
}

.select_theme_dark .select__input {
    border: 1px solid #404447;
    background: #333638;
    color: #fff;
}

.select_theme_dark .select__input:hover {
    border-color: #404447;
    background: #34373A;
}

.select_theme_dark .select__input svg {
    fill: #fff;
}

.select_theme_dark .select__body {
    background: #333638;
    border: 0;
}

.select_theme_dark .select__option:hover {
    background: rgba(255, 255, 255, 0.03);
    color: #fff;
}

.select_theme_dark .select__option_current {
    background: rgba(255, 255, 255, 0.03);
    color: #fff;
}

.select__input {
    position: relative;
    display: block;
    min-height: 24px;
    line-height: 20px;
    padding: 1px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #aaa;
    background: #fff;
    color: #333;
}

.select__input_nopadding {
    padding-left: 0;
    padding-right: 0;
}

.select__input:hover {
    border-color: #1a1c28;
}

.select__input:hover svg {
    opacity: 1;
}

.select__input-svg {
    position: absolute;
    display: block;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0.5;
    fill: #333;
}

.select__body {
    position: relative;
    display: block;
    margin-top: 3px;
    overflow: hidden;
    max-height: 400px;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #aaa;
    z-index: 1;
}

.select__body-scrollable {
    position: relative;
    display: block;
    overflow: auto;
    max-height: 400px;
}

.select__body-scrollable::-webkit-scrollbar {
    width: 2px;
    height: 0px;
}

.select__body-scrollable::-webkit-scrollbar-track {
    background: #0002;
}

.select__body-scrollable::-webkit-scrollbar-thumb {
    background: #fff2;
}

.select__option {
    position: relative;
    display: block;
    padding: 5px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.select__option_asterisk:after {
    content: '*';
    vertical-align: top;
    color: #7C7;
    font-size: 0.7em;
    margin-left: .5em;
}

.select__option_blocked {
    opacity: .4;
    cursor: default;
}

.select__option:hover {
    background: #ededed;
    color: #333;
}

.dialog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.97);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.dialog .close-btn {
    position: absolute;
    display: block;
    top: 20px;
    right: 20px;
    z-index: 1;
}

.dialog .btn {
    margin-right: 24px;
}

.dialog .btn:last-child {
    margin-right: 0;
}

.dialog__window {
    position: relative;
    display: block;
    min-width: 400px;
    padding: 40px 48px 16px;
    background: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.dialog__header {
    position: relative;
    display: block;
    padding-bottom: 24px;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    max-width: 600px;
}

.dialog__content {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 24px;
}

.dialog__actions {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-top: 16px;
    padding-bottom: 16px;
}

.dialog__form {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 0 20px 0;
}

.dialog__textarea {
    position: relative;
    display: block;
    padding: 10px;
    border: 0;
    background: #f5f5f5;
    resize: none;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
}

.dialog label {
    position: relative;
    display: block;
    text-align: left;
    font-weight: 500;
    padding-bottom: 10px;
}

.custom-scroll {
    -webkit-transition: color 3s ease;
    -o-transition: color 3s ease;
    transition: color 3s ease;
}

.custom-scroll::-webkit-scrollbar {
    left: 0;
    bottom: 0;
    height: 5px;
    width: 5px;
    -webkit-transition: color 3s ease;
    transition: color 3s ease;
}

.custom-scroll::-webkit-scrollbar-track {
    background: #1D1F2099;
    -webkit-transition: color 3s ease;
    transition: color 3s ease;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background: transparent;
    -webkit-transition: color 3s ease;
    transition: color 3s ease;
}

.custom-scroll:hover::-webkit-scrollbar-thumb {
    background: #888;
    -webkit-transition: color 3s ease;
    transition: color 3s ease;
}

.presentation {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: visible;
}

.presentation_boxed .bg-trimmer {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.presentation__scale {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.slides-trimmer {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.bg-trimmer {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 0;
    overflow: hidden;
}

.backgrounds {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 0;
}

.backgrounds .slide-bg__screen {
    display: block;
}

.slide-bg {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
}

.slide-bg .slide-bg__image, .slide-bg .slide-bg__color {
    position: absolute;
    display: block;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.slide-bg .slide-bg__color {
    top: .7px;
    right: .7px;
    bottom: .7px;
    left: .7px;
}

.slide-bg .slide-bg__video {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.slide-bg .slide-bg__video video {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -o-object-fit: cover;
    object-fit: cover;
}

.slides {
    position: relative;
    overflow: visible;
    z-index: 1;
}

.slideEar {
    pointer-events: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #888;
    opacity: .25;
    position: absolute;
    z-index: 9999;
}

.slideEar_top {
    width: 100%;
    left: 0;
}

.slideEar_bottom {
    width: 100%;
    left: 0;
}

.slideEar_left {
    height: 100%;
    top: 0;
}

.slideEar_right {
    height: 100%;
    top: 0;
}

.slide {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    pointer-events: none;
}

.slide_active {
    pointer-events: auto;
}

.slide_active .curve svg > * {
    pointer-events: all;
}

.slide__content {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.slide__content-trimmer {
    overflow: hidden;
    position: relative;
}

.slide__content-trimmer_transparent {
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

.slide__content-shifter {
    width: 100%;
    height: 100%;
    position: relative;
}

.text-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
}

.text {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-width: 5px;
    outline: none;
}

.text-selected .text {
    background-color: #8881;
}

.text .p {
    position: relative;
}

.text .li:before {
    display: inline-block;
    content: attr(data-list-index);
    min-width: 1.3em;
}

.text span {
    position: relative;
    white-space: pre-wrap;
    outline: none;
}

.text span::-moz-selection {
    color: inherit;
    background: #c8c8de;
}

.text span::selection {
    color: inherit;
    background: #c8c8de;
}

.thumbs-bar {
    width: 130px;
    height: 100%;
    border-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    transition: all .3s ease;
}

.thumbs-bar__scroller {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 15px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.thumbs-bar__modes {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    width: 40px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.thumbs-bar__mode {
    position: relative;
    display: block;
    height: 47px;
    width: 100%;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.thumbs-bar__mode svg {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0.5;
}

.thumbs-bar__mode:hover svg {
    opacity: 1;
}

.thumbs-bar__mode__active svg {
    opacity: 1;
}

.thumbs-bar__menu.context-menu {
    position: fixed;
    display: block;
    z-index: 1;
    width: 120px;
}

.thumbs-bar__menu-btn {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    opacity: 0;
    z-index: 1;
    overflow: hidden;
}

.thumbs-bar__menu-btn svg {
    position: relative;
    display: block;
    margin: 5px auto;
}

.thumbs-bar__item {
    position: relative;
    display: block;
    margin: 0 5px;
    border: 2px solid transparent;
    height: 60px;
    min-width: 90px;
    width: 90px;
    cursor: pointer;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.thumbs-bar__item:hover {
    border-color: rgba(255, 255, 255, 0.2);
}

.thumbs-bar__item:hover .thumbs-bar__item-inner:after {
    background: #0015;
}

.thumbs-bar__item-inner {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}

.thumbs-bar__item-inner:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.thumbs-bar__item:hover .thumbs-bar__menu-btn {
    opacity: 1;
}

.thumbs-bar__item .slide-bg {
    opacity: 1;
}

.thumbs-bar__item .slide {
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
}

.thumbs-bar__item.thumbs-bar__drop-shadow {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.thumbs-bar .thumbs-bar__add-new-slide {
    border: 2px dashed #aaaaaa;
    border-radius: 5px;
    height: 56px;
    width: 86px;
    top: 2px;
    opacity: 0.5;
}

.thumbs-bar .thumbs-bar__add-new-slide:hover {
    opacity: 1;
}

.thumbs-bar .thumbs-bar__add-new-slide:hover .expand-templates-button {
    opacity: 0.3;
}

.thumbs-bar .thumbs-bar__add-new-slide:after {
    content: '+';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #aaaaaa;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    height: 24px;
}

.thumbs-bar .thumbs-bar__add-new-slide:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 20px;
    right: -20px;
    pointer-events: none;
}

.thumbs-bar .slide-thumb__delete-slide {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
    right: -10px;
    top: -10px;
}

.thumbs-bar .object svg {
    opacity: 1 !important;
}


body {
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

body div {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.viewer .slides-viewer {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: 100vh;
    background: #1c1e1f;
    min-height: calc(100% - 150px);
}

.viewer .slides-viewer.fullscreen .main-block {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.viewer .slides-viewer.fullscreen .bar {
    background-color: #00000073;
}

.viewer .slides-viewer.fullscreen:not(.show-thumbs-bar) .thumbs-bar {
    -webkit-transform: translateX(-130px);
    -ms-transform: translateX(-130px);
    transform: translateX(-130px);
}

.viewer .slides-viewer.fullscreen:not(.show-horizontal-bars) .top-bar {
    -webkit-transform: translateY(-51px);
    -ms-transform: translateY(-51px);
    transform: translateY(-51px);
}

.viewer .slides-viewer.fullscreen:not(.show-horizontal-bars) .bottom-bar {
    -webkit-transform: translateY(51px);
    -ms-transform: translateY(51px);
    transform: translateY(51px);
}

.viewer .main-block {
    position: absolute;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    top: 51px;
    left: 130px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: calc(100% - 130px);
    height: calc(100% - 102px);
    z-index: 0;
}

.viewer .main-block_iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.viewer .workspace {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.viewer .thumbs-bar {
    width: 130px;
    height: 100%;
    border-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.viewer .thumbs-bar__item {
    width: 90px;
    min-width: 90px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    border: none;
}

.viewer .thumbs-bar__item__active {
    outline: 2px solid #10afff;
}

.viewer .thumbs-bar__scroller {
    padding-top: 20px;
    padding-bottom: 20px;
}

.viewer .thumbs-bar:before, .viewer .thumbs-bar:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(#212225), to(transparent));
    background: -o-linear-gradient(top, #212225, transparent);
    background: linear-gradient(to bottom, #212225, transparent);
    z-index: 2;
}

.viewer .thumbs-bar:before {
    top: 0;
}

.viewer .thumbs-bar:after {
    bottom: 0;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.viewer .thumbs-bar__scroller {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #212225;
    height: auto;
    left: 0;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.bar {
    position: fixed;
    left: 0;
    width: 100%;
    height: 51px;
    letter-spacing: -1px;
    background-color: #1c1e1f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 10;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.bar.top-bar {
    top: 0;
}

.bar.bottom-bar {
    bottom: 0;
}

.slides-editor .bar.top-bar {
    -webkit-transform: translateY(-51px);
    -ms-transform: translateY(-51px);
    transform: translateY(-51px);
}

.slides-editor .bar.bottom-bar {
    -webkit-transform: translateY(51px);
    -ms-transform: translateY(51px);
    transform: translateY(51px);
}

.slides-editor .bar_active.top-bar {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.slides-editor .bar_active.bottom-bar {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.presentation-name {
    font-family: 'Helvetica';
    font-size: 20px;
    color: #fff;
}

.bottom-bar .handlers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.bottom-bar .handlers__button {
    width: 10px;
    height: 10px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    border-radius: 2px;
    cursor: pointer;
}

.bottom-bar .handlers__button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    background-color: transparent;
    -webkit-transform: translate(-10px, -10px);
    -ms-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
}

.bottom-bar .handlers__button_prev {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-right: 26px;
}

.bottom-bar .handlers__button_next {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    margin-left: 26px;
}

.bottom-bar .handlers__button_disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none;
}

.bottom-bar .handlers__current {
    font-family: 'Helvetica';
    font-size: 18px;
    color: #fff;
}

.bar .service-bar {
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.bar .service-bar__title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.bar .service-bar__title-wrapper:hover > svg, .bar .service-bar__title-wrapper:hover .service-bar__item-title {
    opacity: 1;
}

.bar .service-bar__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    color: #fff;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 45px;
    cursor: pointer;
}

.bar .service-bar__item-title {
    margin-left: 10px;
    font-size: 16px;
    letter-spacing: 0;
}

.bar .service-bar__share {
    position: absolute;
    display: block;
    top: 30px;
    width: 330px;
    height: 20px;
    right: 0;
    background: #222327ee;
    padding: 10px 20px;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

.bar .service-bar__share-title {
    position: relative;
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.bar .service-bar__share-title span {
    opacity: .5;
    margin-right: 10px;
}

.bar .service-bar__share .flex-row {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

.bar .service-bar__share .flex-row_visible {
    opacity: 1;
    pointer-events: auto;
}

.bar .service-bar__share input {
    min-width: 300px;
    background: transparent;
    border: 0;
    color: #fff;
    font-size: 18px;
    outline: none;
}

.bar .service-bar__share svg {
    position: relative;
    display: block;
    height: 20px;
    width: 20px;
    opacity: 0.3;
    margin-right: 10px;
    -webkit-transition: opacity, -webkit-transform .3s ease;
    transition: opacity, -webkit-transform .3s ease;
    -o-transition: opacity, transform .3s ease;
    transition: opacity, transform .3s ease;
    transition: opacity, transform .3s ease, -webkit-transform .3s ease;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
}

.bar .service-bar__share svg:hover {
    opacity: 1;
}

.bar .service-bar__share svg.share-link-copied {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
}

.workspace {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: calc(100% - 230px);
}

.workspace_dropping .editor-tool {
    pointer-events: none;
}

.workspace .canvas {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: hidden;
    background: #1c1e1f;
}

.workspace .canvas.canvas__rulers-on {
    margin: 25px 0 0 25px;
}

.workspace .canvas.canvas__fullscreen {
    width: 100vw;
    height: 100vh;
}

.workspace .canvas.canvas__drawing-on {
    cursor: crosshair !important;
}

.workspace .file-browser {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.workspace__menu {
    position: fixed;
}

.curve {
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 0;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.curve__selected-border {
    opacity: 0;
    fill: transparent;
    stroke-width: 2;
    stroke: #4BFF;
    pointer-events: none;
}

.curve.selected .curve__selected-border {
    opacity: 0.7;
}

.curve svg {
    overflow: visible;
    display: block;
}

.curve svg path {
    stroke-miterlimit: 10;
}

.shape {
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-clip: padding-box;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.shape__circle {
    border-radius: 50%;
}

.shape__drop-media {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #10AFFF33;
    z-index: 2;
}

.shape__selected-border {
    opacity: 0;
    fill: transparent;
    stroke-width: 2;
    stroke: #4BFF;
    pointer-events: none;
}

.shape.selected .shape__selected-border {
    opacity: 0.7;
}

.shape:not(.text-selected) .text-wrapper {
    cursor: default;
    pointer-events: none;
}

.shape > svg {
    overflow: visible;
    position: absolute;
}

.shape > svg path {
    stroke-miterlimit: 10;
}

.shape__gradient, .shape__bg-image {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.shape__bg-image {
    background-position: center;
}

.shape__border {
    pointer-events: none;
}

.shape .text-placeholder {
    padding: 10px;
}

.shape foreignObject {
    overflow: hidden;
}

.cell {
    stroke: transparent;
    stroke-width: 1px;
}

.cell__geometry_handlers > * {
    position: relative;
    z-index: 2;
    stroke-width: 10px;
    stroke: black;
    opacity: 0;
}

.table:not(.text-selected) .cell__geometry_handlers {
    pointer-events: none;
}

.cell__geometry_handlers__top, .cell__geometry_handlers__bottom {
    cursor: row-resize;
}

.cell__geometry_handlers__left, .cell__geometry_handlers__right {
    cursor: col-resize;
}

.table {
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.table.shadow {
    pointer-events: none !important;
}

.table:not(.shadow) {
    opacity: 1;
}

.table__selected-border {
    opacity: 0;
    fill: transparent;
    stroke-width: 2;
    stroke: #4BFF;
    pointer-events: none;
}

.table_blocked .cell-wrapper {
    pointer-events: none;
}

.table.selected .table__selected-border {
    opacity: 0.7;
}

.table .cell-wrapper {
    shape-rendering: crispEdges;
}

.table svg {
    overflow: visible;
}

.table svg .grid__elem {
    background-color: red;
    stroke-width: 2px;
    stroke: #f00;
    z-index: 100;
}

.table svg .cell {
    position: absolute;
}

.table__svg-border {
    position: absolute;
    pointer-events: none;
}

.table_webkit .table__svg-border {
    shape-rendering: crispEdges;
}

.process-message {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #1c1e1fee;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

.process-message_on {
    opacity: 1;
    pointer-events: auto;
}

.process-message_on .process-message__progressbar_loop .process-message__progressbar-strip_fill {
    -webkit-animation-name: progress-loop;
    animation-name: progress-loop;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.process-message__dialog {
    width: 500px;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    background-color: #2a2c2d;
}

.process-message__name {
    height: 45px;
    background-color: #1c1e1f;
    position: relative;
    width: 100%;
    line-height: 45px;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    padding-left: 26px;
    padding-right: 26px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.process-message__text {
    padding-left: 26px;
    padding-right: 26px;
    margin-top: 30px;
    margin-bottom: -15px;
    color: #fffa;
    font-size: 16px;
}

.process-message__text a {
    color: #b4c17b;
}

.process-message__text .dialog-table {
    text-align: left;
    margin: 15px auto;
}

.process-message__text .dialog-table th {
    font-size: 36px;
    padding-left: 0;
    font-weight: normal;
}

.process-message__text .dialog-table td {
    padding-left: 0;
}

.process-message__progressbar {
    margin-top: 45px;
    margin-left: 26px;
    margin-bottom: 45px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: calc(100% - 52px);
    height: 4px;
    position: relative;
    overflow: hidden;
    border-radius: 2px;
}

.process-message__progressbar-strip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    -webkit-transition: -webkit-transform 1s ease;
    transition: -webkit-transform 1s ease;
    -o-transition: transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease, -webkit-transform 1s ease;
}

.process-message__progressbar-strip_bg {
    background-color: #1c1e1f;
}

.process-message__progressbar-strip_fill {
    background-color: #b4c17b;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.process-message__cancel, .process-message__ok {
    margin-bottom: 30px;
    -ms-flex-item-align: center;
    align-self: center;
    width: 110px;
    height: 30px;
    border-radius: 3px;
    border: solid 1px #404447;
    background-color: rgba(255, 255, 255, 0.07);
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    -webkit-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    transition: background-color .3s ease;
}

.process-message__cancel:hover, .process-message__ok:hover {
    background-color: rgba(255, 255, 255, 0.12);
}

.process-message__ok {
    margin-top: 50px;
}

.animation-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1d1f20;
}

.animation-container .curve__selected-border {
    display: none;
}

.animation-container .shape__selected-border {
    display: none;
}

.animation-container .table__selected-border {
    display: none;
}

.animation-container .text-placeholder {
    display: none;
}

.animation-container .presentation {
    background-color: #000;
}

.animation-container .slides {
    position: absolute;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    top: 0;
    left: 0;
}

.animation-container .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.animation-container .screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.animation-container .screen__fragment {
    height: 100%;
    width: 100%;
    background-color: #fff;
}

.canvas-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
</style>
